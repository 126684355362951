<template>
  <scrollable-container :class="$style.scrollableContainer">
    <placeholder v-show="state === reportEnum.requestAwaiting" :text="text" @buttonClick="buildReport()" />
    <loader v-show="state === reportEnum.tableLoading" />
    <container v-show="state === reportEnum.tableReady" id="printable" :class="[
      { [$style.message]: state === reportEnum.requestAwaiting },
      $style.scrollableContent,
    ]"
    >
      <table :class="$style.table">
        <thead>
          <tr :class="$style.header">
            <td v-for="column in columns" :key="column.name" :style="{ minWidth: column.width }"
                @click="changeSort(column)"
            >
              <div :class="$style.tdInline">
                {{ column.name }}
              </div>
              <img src="@/assets/images/icons/table/sort-arrow.svg" :class="[
                $style.tdInline,
                $style.arrow,
                {
                  [$style.asc]: column.sort == 2,
                  [$style.hidden]: column.sort == 0,
                },
              ]"
              >
            </td>
          </tr>
        </thead>

        <tbody v-for="(department, index) in sortedDeps" :key="index">
          <tr v-show="department.isVisible" :class="$style.item">
            <td :style="indent(department.depth)" :class="[
              { [$style.department]: isFolder(department) },
              { [$style.user]: !isFolder(department) },
            ]" @click="expand(department)"
            >
              <div v-if="isFolder(department)" :class="[
                $style.expandButton,
                { [$style.expanded]: department.isExpanded },
              ]"
              />

              <img v-if="isFolder(department)" :class="$style.iconFolder"
                   src="@/assets/images/icons/computer/folder.svg"
              >

              <div v-if="isFolder(department)">
                {{ department.name }}
              </div>

              <div v-if="!isFolder(department)">
                {{ department.nameUser }}
              </div>
            </td>
            <td v-for="(column, number) in columns.slice(1)" :key="column.field + index + number">
              <div v-if="department.appsData">
                {{ getOutput(department.appsData[column.field], column.field) }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </container>
  </scrollable-container>
</template>

<script>
import ScrollableContainer from '@/components/common/blocks/scrollable-container.vue';
import Container from '@/components/common/blocks/container.vue';
import Placeholder from '@/components/common/blocks/report-placeholder.vue';
import { getAppsTime } from '@/api/methods/reports/users/index';
import { convertSecondsToDays } from '@/helpers/format-date';
import Loader from '@/components/common/blocks/loader.vue';

export default {
  components: {
    ScrollableContainer,
    Container,
    Placeholder,
    Loader,
  },
  data: () => ({
    name: 'Отчет по времени работы ПК и приложений',
    exportArray: [],
    reportTree: [],
    tableArray: [],
    state: 2,
    reportEnum: Object.freeze({
      tableReady: 1,
      requestAwaiting: 2,
      tableLoading: 3,
    }),
    text: 'Для построения отчета нажмите кнопку',
    columns: [
      { name: 'Пользователь', width: '150px', field: 'name', sort: 2 },
      { name: 'Включен', width: '95px', field: 'worktime', sort: 0 },
      { name: '% акт-ти', width: '82px', field: 'activePercent', sort: 0 },
    ],
  }),
  computed: {
    sortedDeps() {
      const calc = this.reportTree.slice(0).sort(this.compare);

      calc.forEach((element) => {
        if (element.type === 'folder') this.sortNodes(element.children);
      });

      this.updateTableArray(calc);
      if (this.exportArray.length === 0 && this.tableArray.length > 0) this.makeExportArray();
      return this.tableArray;
    },
    dateRange() {
      return this.$store.getters['filters/formattedDateRange'];
    },
    checkedComputers() {
      return this.$store.getters['pageSpecificData/checkedComputersList'];
    },
    selectedApps() {
      return this.$store.getters['filters/selectedApps'];
    },
    computersTree() {
      const tree = this.$store.getters['pageSpecificData/computersTree'];
      let treeCopy = JSON.parse(JSON.stringify(tree));

      treeCopy = this.cutUncheckedNodes(treeCopy);

      return treeCopy;
    },
    isWorktimeOnly() {
      return this.$store.getters['filters/isWorktimeOnly'];
    },
  },
  watch: {
    dateRange(oldValue, newValue) {
      if (
        oldValue.dateFrom.toString() !== newValue.dateFrom.toString()
        || oldValue.dateTo.toString() !== newValue.dateTo.toString()
      ) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    checkedComputers(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    selectedApps(oldArray, newArray) {
      if (oldArray !== newArray) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
    isWorktimeOnly(oldValue, newValue) {
      if (oldValue !== newValue) {
        this.state = this.reportEnum.requestAwaiting;
      }
    },
  },
  methods: {
    makeExportArray() {
      this.tableArray.forEach((item) => {
        const resObj = {};
        if (item.type !== 'folder') {
          for (let j = 0; j < this.columns.length; j++) {
            const { field } = this.columns[j];
            if (field === 'name') resObj[this.columns[j].name] = item.nameUser;
            else {
              const res = item.appsData[field];
              if (!res) resObj[this.columns[j].name] = 0;
              else if (field === 'activePercent') resObj[this.columns[j].name] = res.toFixed(1);
              else if (field === 'worktime') resObj[this.columns[j].name] = convertSecondsToDays(res);
              else resObj[this.columns[j].name] = `${res.toFixed(1)}%`;
            }
          }
          this.exportArray.push(resObj);
        }
      });
      this.$store.commit('exportData/setAppsTime', { name: this.name, data: this.exportArray });
    },
    changeVisibility(item, bool) {
      item.isVisible = bool;
      if (item.type === 'folder' && item.isExpanded === bool && item.children) {
        item.children.forEach((child) => this.changeVisibility(child, bool));
      }
    },
    expand(item) {
      if (item.type === 'folder') {
        item.isExpanded = !item.isExpanded;

        if (item.children) {
          item.children.forEach((child) => this.changeVisibility(child, item.isExpanded));
        }
      }
    },
    indent(depth) {
      return {
        paddingLeft: `${depth * 10}px`,
      };
    },
    convertSecondsToDays(secNum) {
      return convertSecondsToDays(secNum);
    },
    getOutput(data, field) {
      if (!data) return 0;

      if (field === 'activePercent') return data.toFixed(1);
      if (field === 'worktime' || this.selectedApps.length > 0) return this.convertSecondsToDays(data);

      if (this.selectedApps.length === 0) return `${data.toFixed(1)}%`;
      return data;
    },
    isFolder(node) {
      if (node.type === 'folder') return true;
      return false;
    },
    changeSort(column) {
      const { sort } = column;

      this.columns.forEach((col) => {
        col.sort = 0;
      });

      if (sort === 1) {
        column.sort = 2;
      } else column.sort = 1;
    },
    updateTableArray(calc) {
      this.tableArray = [];
      calc.forEach((node) => this.generateArrayFromTree(node, 1));
    },
    generateArrayFromTree(node, depth) {
      node.depth = depth;

      this.tableArray.push(node);

      if (node.type === 'folder') {
        node.children.forEach((child) => this.generateArrayFromTree(child, depth + 1));
      }
    },
    sortNodes(nodes) {
      nodes.sort(this.compare);

      nodes.forEach((node) => {
        if (node.type === 'folder') this.sortNodes(node.children);
      });
    },
    compare(a, b) {
      let index = this.columns.findIndex((x) => x.sort > 0);
      if (index < 0) index = 0;

      const key = this.columns[index].field;
      let a1 = a;
      let b1 = b;

      if (key === 'name') {
        if (a1.type === 'folder') a1 = a1[key].toLowerCase();
        else a1 = a1.nameUser.toLowerCase();
        if (b1.type === 'folder') b1 = b1[key].toLowerCase();
        else b1 = b1.nameUser.toLowerCase();
      } else {
        if (a.appsData && a.appsData[key]) a1 = a.appsData[key];
        else a1 = 0;
        if (b.appsData && b.appsData[key]) b1 = b.appsData[key];
        else b1 = 0;
      }

      if (!a1) a1 = 0;
      if (!b1) b1 = 0;

      if (this.columns[index].sort === 1) {
        if (a1 > b1) {
          return -1;
        }

        if (a1 < b1) {
          return 1;
        }
        return 0;
      }

      if (this.columns[index].sort === 2) {
        if (a1 < b1) {
          return -1;
        }

        if (a1 > b1) {
          return 1;
        }

        return 0;
      }
      return 0;
    },
    cutUncheckedNodes(tree) {
      const childrenList = [];

      if (tree) {
        tree.forEach((node) => {
          if (node.checked) childrenList.push(node);
          else if (node.type === 'folder' && node.children) {
            node.children = this.cutUncheckedNodes(node.children);

            if (node.children.length > 0) childrenList.push(node);
          }
        });
      }

      return childrenList;
    },
    async buildReport() {
      if (
        this.dateRange.dateFrom
        && this.dateRange.dateTo
        && this.computersTree
      ) {
        this.state = this.reportEnum.tableLoading;
        await this.fetch();
      }
    },
    async fetch() {
      this.columns.splice(3);

      this.reportTree = [];
      this.exportArray = [];
      let workTimeOnly = 0;

      if (this.isWorktimeOnly) workTimeOnly = 1;

      this.promise = await getAppsTime(
        this.computersTree,
        this.dateRange.dateFrom,
        this.dateRange.dateTo,
        workTimeOnly,
        this.selectedApps,
      ).then(({ data }) => {
        if (data) {
          this.reportTree = data.result.computersTree;

          if (data.result.appsList.length > 0) {
            data.result.appsList.forEach((app) => {
              this.columns.push({
                name: app,
                width: '100px',
                field: app,
                sort: 0,
              });
            });
          }
          this.columns.push({
            name: 'Прочие',
            width: '100px',
            field: 'other',
            sort: 0,
          });

          this.state = this.reportEnum.tableReady;
        } else this.state = this.reportEnum.requestAwaiting;
      });
    },
  },
};
</script>

<style lang="scss" module>
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background: white;
}

::-webkit-scrollbar-thumb {
  background: $branchColor;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background: darkgray;
}

.scrollableContent {
  margin: 0 8px 0 0;
  padding: 0 12px 0 20px;
  box-shadow: $shadow;
  flex: 1;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
}

.table {
  width: 100%;
  margin-bottom: 15px;
  border-collapse: collapse;
  font-weight: 400;
  word-break: break-word;
  border: 1px solid $borderColor;
  padding-bottom: 20px;
}

.header {
  background: #dbf1ff;
  height: 31px;
  position: sticky;
  top: -2px;
  z-index: 3;

  td {
    border: 1px solid $borderColor;
    align-items: center;
    text-align: center;
    color: #232326;
    letter-spacing: -0.08px;
    font-size: 13px;
    padding: 7px 4px 4px 10px;
    cursor: pointer;
  }
}

.tdInline {
  display: inline-flex;
}

.arrow {
  float: right;
  margin-top: 7px;
  margin-right: 5px;

  &.asc {
    transform: rotate(180deg);
  }

  &.hidden {
    visibility: hidden;
  }
}

.item {
  height: 29px;
  cursor: pointer;
  border-bottom: 0.2px solid #eee;

  &:hover {
    background: $light-gray;
  }

  td {
    border-right: 1px solid $borderColor;
    border-left: 1px solid $borderColor;
    align-items: center;
    letter-spacing: -0.24px;
    color: $black;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    padding: 0px 5px;

    &.user {
      font-size: 14px;
      letter-spacing: 0px;
      text-align: left;
      padding-top: 7px;
      padding-right: 4px;
      padding-bottom: 7px;
    }

    &.department {
      font-weight: 500;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0px;
      text-align: left;
      padding: 7px 4px 7px 1px;
      display: flex;
      position: relative;
      border-right: 0px;
    }
  }
}

.iconFolder {
  padding-right: 5px;
}

.expandButton {
  flex-shrink: 0;
  margin-right: 13px;
  width: 14px;
  height: 14px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid $branchColor;
  background: $white;
  z-index: 1;
  margin-left: -17px;

  &::before,
  &::after {
    content: '';
    background-color: $blue;
    width: 8px;
    height: 2px;
    position: absolute;
    transition: 0.25s ease-out;
  }

  &::before {
    transform: rotate(90deg);
  }

  &::after {
    transform: rotate(180deg);
  }

  &.expanded {
    &::before {
      transform: rotate(0);
    }

    &::after {
      transform: rotate(0);
    }
  }
}
</style>
